<template>
  <a-card :bordered="false">
    <a-row class="query-row" type="flex" :gutter="12">
      <a-col style="width:240px">
        <span>直播ID：</span>
        <a-input
          v-model.trim="queryData.roomId"
          allowClear
          placeholder="请输入直播ID"
        ></a-input>
      </a-col>
      <a-col style="width:240px">
        <span>分享人ID：</span>
        <a-input
          v-model.trim="queryData.shareId"
          allowClear
          placeholder="请输入分享人ID"
        ></a-input>
      </a-col>
      <a-col style="width:200px">
        <span>手机号：</span>
        <a-input
          v-model.trim="queryData.mobile"
          allowClear
          placeholder="请输入手机号"
        ></a-input>
      </a-col>
      <a-col style="width:380px">
        <span>分享时间：</span>
        <a-range-picker 
        v-model="shareTimeRange"
        :ranges="pickerRange" 
        :show-time="showTime"/>
      </a-col>
      <a-col>
        <a-button
          @click="
            () => {
              this.pageNum = 1;
              this.loadTableData();
            }
          "
          type="primary"
          >查询</a-button
        >
        <a-button
          style="margin-left:10px"
          @click="exportData"
          type="primary"
          >导出</a-button
        >
      </a-col>
    </a-row>
    <a-table
      :columns="columns"
      :dataSource="tableData"
      :pagination="false"
      :rowKey="record => record.id"
      :loading="loading"
      :scroll="{x: true}"
      size="default"
    >
    </a-table>
    <a-pagination
      style="display: flex; flex-direction: row; justify-content: flex-end; margin:10px 10px 5px 0"
      size="small"
      v-model="pageNum"
      :total="total"
      :showTotal="total => `共 ${total} 条`"
      showLessItems
      showSizeChanger
      showQuickJumper
      :pageSize.sync="pageSize"
      @change="loadTableData"
      @showSizeChange="
        () => {
          this.pageNum = 1;
          this.loadTableData();
        }
      "
    />
    <!--导出modal-->
    <ExportModal
      ref="exportModal"
      :exportUrl="exportUrl"
      :params="handleParams()"
      :total="total"
    >
    </ExportModal>
  </a-card>


</template>

<script>
import moment from 'moment';
import api from '@/api';
import util from '@/libs/util';
import ExportModal from '@/components/ExportModal'
export default {
  name: 'LiveShareList',
  components:{
    ExportModal
  },
  data() {
    return {
      exportUrl: api.marketManage.exportShareList,
      pageNum: 1,
      total: 0,
      pageSize: 10,
      loading: false,
      showTime:{
        format: 'HH:mm:ss',
        defaultValue:[moment('00:00:00', 'HH:mm:ss'),moment('23:59:59', 'HH:mm:ss')]
      },
      columns: util.filterAuthColumns([
        {
          title: '序号',
          dataIndex: 'no',
          align: 'center',
          ellipsis: true,
          customRender: (text, record, index) => {
            return index + 1;
          }
        },
        {
          title: '直播ID',
          dataIndex: 'roomId',
          align: 'center',
          ellipsis: true
        },
        {
          title: '分享人ID',
          dataIndex: 'shareId',
          align: 'center',
          ellipsis: true
        },
        {
          title: '手机号',
          dataIndex: 'mobile',
          align: 'center',
          ellipsis: true
        },
        {
          title: '角色',
          dataIndex: 'roleStr',
          align: 'center',
          ellipsis: true
        },
        {
          title: '等级',
          dataIndex: 'userLevelStr',
          align: 'center',
          // customRender: (text, record) => {
          //   return (
          //     ['小汇同学', '小汇推介官', '小汇俱乐部', '小汇服务商'][text] || ''
          //   );
          // }
        },
        {
          title: '分享次数',
          dataIndex: 'shareNum',
          align: 'center',
        },
        {
          title: '分享人数',
          dataIndex: 'shareUserNum',
          align: 'center',
        },
        {
          title: '下单人数',
          dataIndex: 'orderNum',
          align: 'center',
        },
        {
          title: '下单转化率',
          dataIndex: 'orderRate',
          align: 'center',
        },
       
      ]),
      pickerRange:{ 
      '今日': [moment().startOf('day'), moment().endOf('day')], 
      '本周': [moment().startOf('week'), moment().endOf('week')], 
      '本月': [moment().startOf('month'), moment().endOf('month')]
      },
      tableData: [],
      shareTimeRange:undefined,
      queryData: {
        mobile: undefined,
        roomId: undefined,
        shareId: undefined,
      }
    };
  },
  mounted() {
    this.loadTableData();
    // 初始化
    this.pickerRange = { 
      '今日': [moment().startOf('day'), moment().endOf('day')], 
      '本周': [moment().startOf('week'), moment().endOf('week')], 
      '本月': [moment().startOf('month'), moment().endOf('month')]
    }
  },
  methods: {
    moment,
    loadTableData() {
      let params = {
        ...this.queryData,
        pageNum: this.pageNum,
        pageSize: this.pageSize
      };
      let st  = this.shareTimeRange
      if(st && st[0]){
        let startTime = st[0] ? st[0].valueOf() / 1000 : undefined
        let endTime = st[1] ? st[1].valueOf() / 1000 : undefined
        params.startTime = startTime || undefined;
        params.endTime = endTime || undefined;
      }
      this.loading = true;
      api.marketManage
        .shareList(this.handleParams())
        .then(res => {
          if (res.code === '00000') {
            this.tableData = res.data.dataList;
            if (Object.values(params).filter(i => i).length === 2) {
              //没有查询条件时只能展示50条
              this.total = res.data.total;
            } else {
              this.total = res.data.total;
            }
          } else {
            this.$message.error(res.msg);
          }
        })
        .finally(() => (this.loading = false));
    },
    exportData () {
      this.$refs.exportModal.show()
    },
    handleParams(){
      let { roomId,mobile,shareId } = this.queryData
      let shareTimeRange = this.shareTimeRange
      let shareBeginTime = shareTimeRange && shareTimeRange[0] ? shareTimeRange[0].valueOf() / 1000 : undefined
      let shareEndTime = shareTimeRange && shareTimeRange[1] ? shareTimeRange[1].valueOf() / 1000 : undefined
      roomId = roomId || undefined
      mobile = mobile || undefined
      shareId = shareId || undefined
      let p = {
        roomId,mobile,shareId,shareBeginTime,shareEndTime,
        pageNum:this.pageNum,
        pageSize:this.pageSize
      }
      return p
    }
  }
};
</script>

<style scoped lang="less"></style>
