var render = function render(){var _vm=this,_c=_vm._self._c;return _c('a-card',{attrs:{"bordered":false}},[_c('a-row',{staticClass:"query-row",attrs:{"type":"flex","gutter":12}},[_c('a-col',{staticStyle:{"width":"240px"}},[_c('span',[_vm._v("直播ID：")]),_c('a-input',{attrs:{"allowClear":"","placeholder":"请输入直播ID"},model:{value:(_vm.queryData.roomId),callback:function ($$v) {_vm.$set(_vm.queryData, "roomId", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"queryData.roomId"}})],1),_c('a-col',{staticStyle:{"width":"240px"}},[_c('span',[_vm._v("分享人ID：")]),_c('a-input',{attrs:{"allowClear":"","placeholder":"请输入分享人ID"},model:{value:(_vm.queryData.shareId),callback:function ($$v) {_vm.$set(_vm.queryData, "shareId", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"queryData.shareId"}})],1),_c('a-col',{staticStyle:{"width":"200px"}},[_c('span',[_vm._v("手机号：")]),_c('a-input',{attrs:{"allowClear":"","placeholder":"请输入手机号"},model:{value:(_vm.queryData.mobile),callback:function ($$v) {_vm.$set(_vm.queryData, "mobile", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"queryData.mobile"}})],1),_c('a-col',{staticStyle:{"width":"380px"}},[_c('span',[_vm._v("分享时间：")]),_c('a-range-picker',{attrs:{"ranges":_vm.pickerRange,"show-time":_vm.showTime},model:{value:(_vm.shareTimeRange),callback:function ($$v) {_vm.shareTimeRange=$$v},expression:"shareTimeRange"}})],1),_c('a-col',[_c('a-button',{attrs:{"type":"primary"},on:{"click":() => {
            this.pageNum = 1;
            this.loadTableData();
          }}},[_vm._v("查询")]),_c('a-button',{staticStyle:{"margin-left":"10px"},attrs:{"type":"primary"},on:{"click":_vm.exportData}},[_vm._v("导出")])],1)],1),_c('a-table',{attrs:{"columns":_vm.columns,"dataSource":_vm.tableData,"pagination":false,"rowKey":record => record.id,"loading":_vm.loading,"scroll":{x: true},"size":"default"}}),_c('a-pagination',{staticStyle:{"display":"flex","flex-direction":"row","justify-content":"flex-end","margin":"10px 10px 5px 0"},attrs:{"size":"small","total":_vm.total,"showTotal":total => `共 ${total} 条`,"showLessItems":"","showSizeChanger":"","showQuickJumper":"","pageSize":_vm.pageSize},on:{"update:pageSize":function($event){_vm.pageSize=$event},"update:page-size":function($event){_vm.pageSize=$event},"change":_vm.loadTableData,"showSizeChange":() => {
        this.pageNum = 1;
        this.loadTableData();
      }},model:{value:(_vm.pageNum),callback:function ($$v) {_vm.pageNum=$$v},expression:"pageNum"}}),_c('ExportModal',{ref:"exportModal",attrs:{"exportUrl":_vm.exportUrl,"params":_vm.handleParams(),"total":_vm.total}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }